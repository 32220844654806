export enum Tags {
	MACHINE_LEARNING = "Machine Learning",
	DATA_PREPARATION = "Data preparation",
	DATASET_MANAGEMENT = "Dataset management",
	GRAPH_DATABASE = "Graph database",
	HIGH_PERFORMANCE = "High performance",
	KNOWLEDGE_GRAPH = "Knowledge graph",
	MULTIMODAL_AI = "Multimodal AI",
	MULTIMODAL_DATA = "Multimodal data",
	SEMANTIC_SEARCH = "Semantic search",
	TEXT_SEARCH = "Text search",
	USABILITY = "Usability",
	VECTOR_DATABASE = "Vector database",
	VECTOR_SEARCH = "Vector search / similarity search",
	VISUAL_DATA = "Visual data",
	VISUAL_DATA_EXPLORER = "Visual data explorer",
    RAG = "Retrieval augmented generation (RAG)",
    RECOMMENDATION = "Recommendation systems",
    GENAI = "Generative AI",
    GRAPH_SEARCH = "Graph filtering / metadata filtering",
    PRIVACY = "Data privacy and security",
    DEBUGGING = "Data debugging",
}
